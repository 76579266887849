import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  StyledWrapper,
  StyledAddendum,
  StyledForm,
  StyledFormSection,
  StyledCheckbox,
  StyledCheckboxWrapper,
  StyledFieldLabel,
  StyledCheckboxLabel,
  StyledTd,
  StyledTh,
  EditButton,
  ContractButton,
} from './CXEditOrganisationForm.styled';
import {
  S_tr,
  S_table,
  S_tbody,
} from '../../../../../../../../../styled-components/StyledTable';
import {
  StyledDataBlock,
  StyledDataBlocks,
} from '../../../../../../../../../styled-components/StyledDataBlocks';
import StyledLabel from '../../../../../../../../../styled-components/StyledLabel';
import { useMutation, useQuery } from '@apollo/client';
import { READ_ORGANISATION } from '../../../../../../../../../queries/OrganisationQueries';
import useGetVHCounts from '../../../../../../../../../components/VirtualHumanCreationWizard/useGetVHCounts';
import { Icon, Tooltip } from '@virtidev/toolbox';
import { GET_ORG_VH_COUNTS } from '../../../../../../../../../components/VirtualHumanCreationWizard/OrgVHCounts.query';
import {
  EDIT_CX_ORGANISATION,
  READ_PLATFORM_LICENSES,
} from './CXEditOrganisationForm.query';
import OrgSingleTextFieldForm from '@core/components/Cx/OrgSingleTextFieldForm/OrgSingleTextFieldForm';
import PlatformLicenseModal from '@core/components/Cx/PlatformLicenseModal/PlatformLicenseModal';
import { getLabelForPlatformLicense } from '@core/components/Cx/OrgTable/customerStageOptions';
import { useFlashMessage } from '@core/components/FlashMessage';

const CXEditOrganisationForm = ({ orgID }) => {
  const { addFlashMessage } = useFlashMessage();

  const { data, loading: loadingReadOrg } = useQuery(READ_ORGANISATION, {
    variables: { ID: orgID },
    skip: !orgID,
  });

  const [editingPlatformLicense, setEditingPlatformLicense] =
    React.useState(null);
  const [platformLicenseFormVisible, setPlatformLicenseFormVisible] =
    React.useState(false);

  const showPlatformLicenseForm = useCallback(() => {
    setPlatformLicenseFormVisible(true);
  }, []);

  const hidePlatformLicenseForm = useCallback(() => {
    setPlatformLicenseFormVisible(false);
    setEditingPlatformLicense(null);
  }, []);

  const {
    data: platformLicenseData,
    loading: loadingPlatformLicenses,
    refetch: refetchPlatformLicenses,
  } = useQuery(READ_PLATFORM_LICENSES, {
    variables: {
      filter: {
        Organisation: { ID: { eq: orgID } },
      },
    },
    skip: !orgID,
  });

  const platformLicenses = useMemo(() => {
    const licenses =
      platformLicenseData?.readPlatformLicenses?.edges?.map(
        (edge) => edge.node
      ) || [];
    licenses.sort((a, b) => {
      const aStartDate = new Date(a.StartDate);
      const bStartDate = new Date(b.StartDate);
      if (aStartDate > bStartDate) return -1;
      if (aStartDate < bStartDate) return 1;
      const aCreated = new Date(a.Created);
      const bCreated = new Date(b.Created);
      if (aCreated > bCreated) return -1;
      if (aCreated < bCreated) return 1;
      return 0;
    });
    return licenses;
  }, [platformLicenseData]);

  const currentLicense = useMemo(() => {
    return platformLicenses.filter((license) => {
      if (!license.StartDate) return false;
      return new Date(license.StartDate + 'T00:00:00.000Z') < new Date();
    })[0];
  }, [platformLicenses]);

  const platformLicenseClickHandlers = useMemo(() => {
    return Object.fromEntries(
      platformLicenses.map((license) => [
        license.ID,
        () => {
          setEditingPlatformLicense(license);
          showPlatformLicenseForm();
        },
      ])
    );
  }, [platformLicenses, showPlatformLicenseForm]);

  const [mutateOrganisation, { loading: saving }] = useMutation(
    EDIT_CX_ORGANISATION,
    {
      onError: () => {
        addFlashMessage('Failed to update organisation', 'error');
      },
      refetchQueries: [
        {
          query: GET_ORG_VH_COUNTS,
          variables: {
            OrganisationID: orgID,
          },
        },
      ],
    }
  );

  const loading = loadingReadOrg || loadingPlatformLicenses || saving;

  const organisation = _.get(data, 'readOneOrganisation', {});

  const saveFeature = useCallback(
    (event) => {
      const {
        target: { name, checked },
      } = event;

      const fieldMap = {
        DisableLeaderboard: { label: 'Display Leaderboard', inverted: true },
        Medical: { label: 'Medical', inverted: false },
      };
      const { label: featureName, inverted } = fieldMap[name];
      const enabled = checked;
      const newValue = inverted ? !enabled : enabled;

      mutateOrganisation({
        variables: {
          input: {
            ID: orgID,
            [name]: newValue,
          },
        },
      }).then(({ data: { updateOrganisation: result } }) => {
        const enabled = inverted ? !result[name] : result[name];
        addFlashMessage(
          `${featureName} ${enabled ? 'enabled' : 'disabled'} for ${
            organisation.Name
          }.`
        );
      });
    },
    [mutateOrganisation, addFlashMessage, orgID, organisation]
  );

  const {
    error,
    maxVHs,
    orgVHCount,
    refetch: refetchVHCounts,
    isLoading: vhCountsLoading,
    branchingMax,
    freeformMax,
    branchingTotalCount,
    freeformTotalCount,
    freeAgentsCount,
    allOrgsUnusedFreeformLicenses,
    allOrgsMaxAllowedFreeformLicenses,
    additionalFreeformLicensesAssignable,
  } = useGetVHCounts(orgID, organisation?.VirtualHumanAPI);

  // we don't want to prevent cx admin from lowering licenses (even if new licenses won't necessarily be accommodated by free agents)
  // because that may make it awkward to transition to fully fixed licensing counts
  const maxFreeFormLimit = Math.max(
    organisation?.VirtualHumanFreeformLimit +
      additionalFreeformLicensesAssignable,
    organisation?.VirtualHumanFreeformLimit
  );

  return (
    <>
      <StyledDataBlocks gridCols={orgID ? '1fr 1fr 1fr' : '1fr 1fr'}>
        {orgID && (
          <StyledWrapper>
            <StyledDataBlock>
              <StyledLabel>Settings</StyledLabel>
              <StyledForm>
                <StyledFormSection>
                  <StyledCheckboxWrapper>
                    <StyledCheckboxLabel>
                      Display Leaderboard
                    </StyledCheckboxLabel>
                    <StyledCheckbox
                      name="DisableLeaderboard"
                      disabled={loading}
                      checked={!organisation.DisableLeaderboard}
                      onChange={saveFeature}
                    />
                  </StyledCheckboxWrapper>
                </StyledFormSection>
                <StyledFormSection>
                  <StyledCheckboxWrapper>
                    <StyledCheckboxLabel>
                      Medical{' '}
                      <StyledAddendum>
                        (Admin will only see update after logout)
                      </StyledAddendum>
                    </StyledCheckboxLabel>
                    <StyledCheckbox
                      name="Medical"
                      disabled={loading}
                      checked={!!organisation.Medical}
                      onChange={saveFeature}
                    />
                  </StyledCheckboxWrapper>
                </StyledFormSection>
                <StyledFormSection>
                  <OrgSingleTextFieldForm
                    fieldName={'VirtualHumanAPI'}
                    fieldLabel="Virtual Human API"
                    organisation={organisation}
                    disabled={loading}
                    onComplete={refetchVHCounts}
                  />
                </StyledFormSection>
                <StyledFormSection>
                  <OrgSingleTextFieldForm
                    fieldName={'TUSEndpoint'}
                    fieldLabel="Custom TUS Endpoint"
                    organisation={organisation}
                    disabled={loading}
                  />
                </StyledFormSection>
                <StyledFormSection>
                  <OrgSingleTextFieldForm
                    fieldName={'Partner'}
                    fieldLabel="Partner"
                    organisation={organisation}
                    disabled={loading}
                  />
                </StyledFormSection>
                <StyledFormSection>
                  <OrgSingleTextFieldForm
                    fieldName={'Domain'}
                    fieldLabel="Domain name"
                    placeholder="e.g. example.com"
                    organisation={organisation}
                    disabled={loading}
                  />
                </StyledFormSection>
                <StyledFormSection>
                  <OrgSingleTextFieldForm
                    fieldName={'HubSpotCompanyID'}
                    fieldLabel="HubSpot ID"
                    organisation={organisation}
                    disabled={loading}
                  />
                </StyledFormSection>
              </StyledForm>
            </StyledDataBlock>
          </StyledWrapper>
        )}

        <StyledWrapper>
          <StyledDataBlock>
            <StyledLabel>Licenses</StyledLabel>
            <StyledForm>
              <StyledFormSection>
                <OrgSingleTextFieldForm
                  fieldName={'Licenses'}
                  fieldLabel="User Licenses Sold"
                  type="number"
                  organisation={organisation}
                  disabled={loading}
                />
              </StyledFormSection>
              <StyledFormSection>
                <StyledFieldLabel>
                  All Unused Agents: {vhCountsLoading ? '-' : freeAgentsCount}
                </StyledFieldLabel>
                <StyledFieldLabel>
                  All Unused Freeform Licenses: {allOrgsUnusedFreeformLicenses}
                </StyledFieldLabel>
                <StyledFieldLabel>
                  Actual Free Agents: {additionalFreeformLicensesAssignable}
                </StyledFieldLabel>
              </StyledFormSection>
              <StyledFormSection>
                <OrgSingleTextFieldForm
                  fieldName={'VirtualHumanFreeformLimit'}
                  fieldLabel="Virtual Human Freeform Limit"
                  fieldLabelSuffix={` (in use: ${
                    freeformTotalCount || freeformMax
                      ? `${freeformTotalCount}/${freeformMax}`
                      : '-'
                  })`}
                  type="number"
                  max={maxFreeFormLimit}
                  organisation={organisation}
                  disabled={loading}
                  onComplete={refetchVHCounts}
                />
              </StyledFormSection>
              <StyledFormSection>
                <OrgSingleTextFieldForm
                  fieldName={'VirtualHumanBranchingLimit'}
                  fieldLabel="Virtual Human Branching Limit"
                  fieldLabelSuffix={` (in use: ${
                    branchingTotalCount || branchingMax
                      ? `${branchingTotalCount}/${branchingMax}`
                      : '-'
                  })`}
                  type="number"
                  organisation={organisation}
                  disabled={loading}
                />
              </StyledFormSection>
            </StyledForm>
          </StyledDataBlock>
        </StyledWrapper>

        <StyledWrapper>
          <StyledDataBlock>
            <StyledLabel>Contract</StyledLabel>
            <StyledForm>
              <StyledFormSection>
                <StyledFieldLabel>
                  Customer Stage: {organisation.CustomerStage}
                </StyledFieldLabel>
                {/* <StyledFieldLabel>Contract Status: (TODO)</StyledFieldLabel> */}
                <ContractButton onClick={showPlatformLicenseForm}>
                  Update Contract
                </ContractButton>
              </StyledFormSection>
              <StyledFormSection>
                <StyledFieldLabel>Contract History</StyledFieldLabel>
                <S_table>
                  <S_tbody>
                    <S_tr>
                      <StyledTh>Type</StyledTh>
                      <StyledTh>Start</StyledTh>
                      <StyledTh>Expiry</StyledTh>
                      <StyledTh></StyledTh>
                      <StyledTh></StyledTh>
                    </S_tr>
                    {platformLicenses.map((license) => {
                      return (
                        <S_tr key={license.ID}>
                          <StyledTd>
                            {getLabelForPlatformLicense(license.Type)}
                            {license.ID === currentLicense?.ID ? (
                              <i> (current)</i>
                            ) : (
                              ''
                            )}
                            {new Date(license.StartDate + 'T00:00:00.000Z') >
                            new Date() ? (
                              <i> (future)</i>
                            ) : (
                              ''
                            )}
                          </StyledTd>
                          <StyledTd>{license.StartDate}</StyledTd>
                          <StyledTd>{license.ExpiryDate}</StyledTd>
                          <StyledTd>
                            {license.Notes ? (
                              <Tooltip hoverOpen={true} icon="help">
                                {license.Notes}
                              </Tooltip>
                            ) : (
                              '-'
                            )}
                          </StyledTd>
                          <StyledTd>
                            <EditButton
                              onClick={platformLicenseClickHandlers[license.ID]}
                            >
                              <Icon icon="pen" />
                            </EditButton>
                          </StyledTd>
                        </S_tr>
                      );
                    })}
                  </S_tbody>
                </S_table>
                <PlatformLicenseModal
                  orgID={orgID}
                  visible={platformLicenseFormVisible}
                  hideModal={hidePlatformLicenseForm}
                  license={editingPlatformLicense}
                  onCreate={refetchPlatformLicenses}
                  onDelete={refetchPlatformLicenses}
                />
              </StyledFormSection>
            </StyledForm>
          </StyledDataBlock>
        </StyledWrapper>
      </StyledDataBlocks>
    </>
  );
};

CXEditOrganisationForm.propTypes = {
  orgID: PropTypes.string.isRequired,
};

export default CXEditOrganisationForm;
