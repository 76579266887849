import { useMutation } from '@apollo/client';
import VideoSelectModal from '@core/components/AssetSelectModal/VideoSelectModal';
import { useFlashMessage } from '@core/components/FlashMessage';
import { useFeature } from '@core/components/LaunchDarkly';
import getFileID from '@core/components/MediaUploaders/helpers/getFileID';
import MixedMediaUploader from '@core/components/MediaUploaders/MixedMediaUploader';
import {
  CREATE_SIM_MEDIA,
  CREATE_SIM_VIDEO,
  CREATE_WITH_EXISTING_MEDIA,
  CREATE_WITH_EXISTING_VIDEO,
} from '@core/components/Simulation/CreateSimForm/CreateSimForm.query';
import { TitleContainer } from '@core/components/Simulation/CreateSimForm/CreateSimForm.styled';
import tracker from '@core/helpers/tracker';
import useUser from '@core/helpers/useUser';
import { Button, Icon } from '@virtidev/toolbox';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const CreateSimForm = () => {
  const history = useHistory();
  const { addFlashMessage } = useFlashMessage();
  const [stillImageSimFeature] = useFeature(['image-simulation-support']);
  const { OrganisationID } = useUser();
  const [loading, setLoading] = useState(false);
  const [selecting, setSelecting] = useState(false);

  const [createSimVideo] = useMutation(CREATE_SIM_VIDEO);

  const [createSimMedia] = useMutation(CREATE_SIM_MEDIA);

  const [createWithExistingMedia] = useMutation(CREATE_WITH_EXISTING_MEDIA);

  const [createWithExistingVideo] = useMutation(CREATE_WITH_EXISTING_VIDEO);

  const acceptedTypes = useMemo(
    () => (stillImageSimFeature ? ['video', 'image'] : ['video']),
    [stillImageSimFeature]
  );

  const handleUpload = useCallback(
    async (upload, { content360, type }) => {
      setLoading(true);
      let simId = null;

      if (type === 'video') {
        const result = await createSimVideo({
          variables: {
            URL: upload.url,
            Filename: upload.file.name,
            TusID: getFileID(upload.url),
            OrganisationID,
            Content360: content360,
          },
        });
        simId = result?.data?.createSimulationFromURL?.ID;
      }

      if (type === 'image') {
        const result = await createSimMedia({
          variables: {
            URL: upload.url,
            Filename: upload.file.name,
            TusID: getFileID(upload.url),
            OrganisationID,
            Content360: content360,
          },
        });
        simId = result?.data?.createSimulationFromMediaURL?.ID;
      }

      if (!simId) {
        return;
      }
      tracker.track('simulation_created', {
        sim_id: simId,
      });
      addFlashMessage('Simulation created', 'success');
      history.push(`/simulations/${simId}`);
    },
    [OrganisationID, addFlashMessage, createSimMedia, createSimVideo, history]
  );

  const handleComplete = useCallback(async () => {
    setLoading(false);
  }, []);

  const handleError = useCallback(() => {
    setLoading(false);
  }, []);

  const showSelect = useCallback(() => setSelecting(true), []);

  const hideSelect = useCallback(() => setSelecting(false), []);

  const handleSelectedMedia = useCallback(
    /* extend to support media proper later */
    async (video) => {
      console.log(video);
      let simId = null;

      const { data } = await createWithExistingVideo({
        variables: { videoID: video.ID },
      });

      simId = data?.createSimulationFromVideoMedia?.ID;

      if (!simId) {
        addFlashMessage(
          `Failed to create simulation with '${video.Title}'`,
          'error'
        );
        return;
      }

      addFlashMessage('Simulation created', 'success');
      history.push(`/simulations/${simId}`);

      hideSelect();
    },
    [addFlashMessage, createWithExistingVideo, hideSelect, history]
  );

  return (
    <>
      <MixedMediaUploader
        title={
          <TitleContainer>
            <span>
              What type of media do you want to add? <Icon icon="help" />
            </span>
            <Button color="turquoise" design="transparent" onClick={showSelect}>
              Choose existing media
            </Button>
          </TitleContainer>
        }
        name="asset-uploader"
        acceptedTypes={acceptedTypes}
        warnPrompt
        onError={handleError}
        onCancel={handleError}
        onSuccess={handleUpload}
        onComplete={handleComplete}
        loading={loading}
      />
      {/* replace with select modal that supports media properly */}
      <VideoSelectModal
        visible={selecting}
        organisationID={OrganisationID}
        hideModal={hideSelect}
        onConfirm={handleSelectedMedia}
        loading={loading}
      />
    </>
  );
};

export default CreateSimForm;
